<template>
  <div @click="aa" style="height: 400px">测试</div>
</template>


<script>
export default {
  data() {
    return {
      point: {}
    }
  },
  mounted() {
    let _this = this;

    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition(
        position => {
          const point = position.coords;
          // latitude  longitude
          _this.point = {
            lng: point.longitude,
            lat: point.latitude
          }
        },
        err => {
          console.log(err)
          var geolocation = new BMap.Geolocation();
          geolocation.getCurrentPosition(function(r){
            if(this.getStatus() == BMAP_STATUS_SUCCESS){
              _this.point = r.point;
            }      
          },{enableHighAccuracy: true})
        }
      );
    }else{
      console.log("Geolocation is not supported by this browser.");
      var geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition(function(r){
        if(this.getStatus() == BMAP_STATUS_SUCCESS){
          _this.point = r.point;
        }      
      },{enableHighAccuracy: true})
    }
  },
  methods: {
    aa () {
      alert(JSON.stringify(this.point))
    }
  }
}
</script>
